<script>
/* global bootstrap */
import BaseButtonComponent from "@/components/button/BaseButtonComponent.vue";

export default {
  components: {BaseButtonComponent},
  data() {
    return {
      modal: null
    }
  },
  methods: {
    show() {
      this.modal.show()
    },
    _dismissDialog() {
      this.modal.hide()
    }
  },
  mounted() {
    this.modal = new bootstrap.Modal(this.$refs.submitErrorModal, {
      keyboard: false
    });
  }
}

</script>

<template>
  <div id="modal" ref="submitErrorModal" class="modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen d-flex align-items-center justify-content-center"
         style="background: #20242D">
      <div class="row">
        <div class="col-12  d-flex align-items-center justify-content-center">
          <span class="circleError">
            <i class="bi bi-exclamation-lg"></i>
          </span>
        </div>
        <div class="col-12 mt-4 d-flex align-items-center justify-content-center">
          <h3 style="color: white">Ein Fehler ist aufgetreten!</h3>
        </div>
        <div class="col-12 mt-4 d-flex align-items-center justify-content-center">
          <p style="color: white">Probiere es zu einem späteren Zeitpunkt noch einmal.</p>
        </div>
        <div class="col-12 mt-4 d-flex align-items-center justify-content-center">
          <BaseButtonComponent
              button-label="Alle klar!"
              @onClicked=_dismissDialog
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style>

.circleError {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #e52f3d;
  width: 136px;
  height: 136px;
  border-radius: 100px;
  padding: 5px 8px;
  gap: 10px;
}

.bi-exclamation-lg {
  margin-top: 1vh;
  font-size: 8rem;
}

</style>