<script>
import SVGAssetImageComponent from "@/components/SVGAssetImageComponent.vue";

export default {
  components: {SVGAssetImageComponent},
  props: {
    svgName: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    link: {
      type: String,
    }
  }
}
</script>

<template>
  <div class="row align-items-center position-relative">
    <div class="col-12 text-center">
      <SVGAssetImageComponent
          :asset-name=svgName
          max-height="200px"
      />
    </div>
    <div class="col-12 mt-2 text-center">
      {{ title }}
    </div>
    <a v-if=link :href=link class="stretched-link"/>
  </div>
</template>