<template>
  <div class="row">
    <div class="col-12">
      <b>{{ option.title }}</b>
      <span v-if="option.required">*</span>
    </div>
    <div class="col-12" v-if="option.description">
      <small>{{ option.description }}</small>
    </div>
    <div class="col-12 mt-2">
      <img
          v-for="value in option.values" :key="value"
          @click="selectValue(value)"
          :src="require(`@/assets/rating${value}.svg`)"
          :alt="`Rating ${value}`"
          class="me-2"
          :class="{
            selected: selectedValue === value,
            notSelected: selectedValue !== value
          }"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object,
      required: true
    },
    initialValue: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      selectedValue: null,
    }
  },
  mounted() {
    if (this.initialValue && this.option.values.includes(this.initialValue)) {
      this.selectValue(this.initialValue);
    }
  },
  methods: {
    selectValue(value) {
      this.selectedValue = value
      this.$emit('onScaleRating', {
        type: this.option.type,
        title: this.option.title,
        name: this.option.name,
        required: this.option.required,
        value: value,
        calculatedValue: Math.round(this.selectedValue * this.option.multiplier * 10) / 10,
      });
    }
  }
};
</script>

<style scoped>
.selected {
  border: 2px solid black;
  padding: 4px;
  box-sizing: border-box;
  cursor: pointer;
}

.notSelected {
  border: 2px solid #fff;
  padding: 4px;
  box-sizing: border-box;
  cursor: pointer;
}

img {
  cursor: pointer;
}
</style>
