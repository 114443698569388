<script setup>

</script>

<template>
  <a href="/">NOT FOUND</a>
</template>

<style scoped>

</style>