<script>
/* global bootstrap */

import BaseButtonComponent from "@/components/button/BaseButtonComponent.vue";

export default {
  components: {BaseButtonComponent},
  data() {
    return {
      modal: null,
      title: "",
      message: "",
      buttonLabel: null
    }
  },
  methods: {
    show({title = null, message = null, buttonLabel = null} = {}) {
      this.title = title
      this.message = message
      this.buttonLabel = buttonLabel
      this.modal.show()
    }
  },
  mounted() {
    this.modal = new bootstrap.Modal(this.$refs.customNotificationDialog, {
      // keyboard: false
    });
  }
}

</script>

<template>
  <div class="modal fade" ref="customNotificationDialog" tabindex="-1" aria-labelledby="exampleModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col-10">
              <b>{{ title }}</b>
            </div>
            <div class="col-2 text-end">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
            </div>
            <div class="row mt-2">
              <div class="col-12 mt-4">
                <span
                    v-html=message
                />
              </div>
            </div>
            <div class="col-12 mt-4" v-if="this.buttonLabel">
              <BaseButtonComponent
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  :button-label=this.buttonLabel
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>