<script>

import SingleLineComponent from "@/components/listitem/SingleLineComponent.vue";

export default {
  components: {SingleLineComponent},
  props: {
    firstLine: {
      type: String,
      required: true
    },
    secondLine: {
      type: String,
      required: true
    }
  }
}

</script>

<template>
  <SingleLineComponent
      :first-line=firstLine
  />
  <br/>
  <span>{{ secondLine }}</span>
</template>