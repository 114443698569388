<script>
import {inject} from "vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import EvaluationRefereeComponent from "@/components/evaluation/EvaluationRefereeComponent.vue";
import CustomNotificationDialog from "@/components/CustomNotificationDialog.vue";
import EvaluationKeeperComponent from "@/components/evaluation/EvaluationKeeperApplication.vue";
import ConfirmationDialogComponent from "@/components/dialog/ConfirmationDialogComponent.vue";

export default {
  components: {
    ConfirmationDialogComponent,
    CustomNotificationDialog,
    EvaluationKeeperComponent,
    EvaluationRefereeComponent,
    LoadingComponent
  },
  setup() {
    const axios = inject('axios')
    return {
      axios: axios
    }
  },
  mounted() {
    const splittedPath = this.$route.fullPath.split('/')
    this.application = splittedPath[2].toLowerCase()
    this.baseUrl = `/evaluation/${this.application}`
    this.fetchData()
  },
  data() {
    return {
      application: "",
      baseUrl: "",
      evaluationToDelete: null,
      fetchedKeeperApplicationData: [],
      fetchedRefereeApplicationData: [],
      isLoading: true
    }
  },
  methods: {
    createDeleteRefereeVoteWarningMessage() {
      let message = `Du bist dabei die Bewertung vom Spiel:<p><br/>${this.evaluationToDelete.phaseName.shortName}: ${this.evaluationToDelete.homeTeam.name} - ${this.evaluationToDelete.awayTeam.name}</p> von:`
      if (this.evaluationToDelete.isVoteByDelegate) {
        message += ` ${this.evaluationToDelete.delegate.name} (Delegierte(r))`
      } else {
        message += ` ${this.evaluationToDelete.refereeA.name} &  ${this.evaluationToDelete.refereeB.name} (Schiedsrichter)`
      }
      message += ` zu löschen`
      return message
    },
    fetchData() {
      this.axios
          .get(this.baseUrl)
          .then((response) => {
            this.isLoading = true
            switch (this.application) {
              case 'kampfgericht':
                this.fetchedKeeperApplicationData = []
                this.fetchedKeeperApplicationData = response.data.values
                break
              case 'schiedsrichter':
                this.handleSchiedsrichterData(response.data.values)
                break
              default:
                break
            }
          })
          .catch((e) => {
            this.$refs.customNotificationDialog.show({
              title: "Oh, oh!",
              message: e.response.data.message,
              buttonLabel: "Alles klar!"
            })
            this.$router.replace(`${this.baseUrl}/login`)
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    handleSchiedsrichterData(values) {
      this.fetchedRefereeApplicationData = this.groupItemByPhaseIdAndThenByMatchId(values)
    },
    onCancelDeleteRefereeVote() {
      this.resetEvaluationToDelete()
      this.$refs.deleteRefereeVoteConfirmationDialog.hide()
    },
    onConfirmDeleteRefereeVoteClicked() {
      this.$refs.deleteRefereeVoteConfirmationDialog.hide()
      this.isLoading = true
      this.axios.delete(`${this.baseUrl}/${this.evaluationToDelete.id}`)
          .then(() => {
            this.fetchData()
            this.resetEvaluationToDelete()
          })
          .catch((e) => {
            this.resetEvaluationToDelete()
            this.isLoading = false
            this.$refs.customNotificationDialog.show({
              title: "Oh, oh!",
              message: e.response.data.message,
              buttonLabel: "Alles klar!"
            })
          })
    },
    onDeleteRefereeVoteClicked(evaluation) {
      this.evaluationToDelete = evaluation
      this.$refs.deleteRefereeVoteConfirmationDialog.show({
        title: "!!! Achtung !!!",
        message: this.createDeleteRefereeVoteWarningMessage(),
        negativeButtonLabel: "Abbrechen",
        positiveButtonLabel: "Löschen"
      })
    },
    resetEvaluationToDelete() {
      this.evaluationToDelete = null
    }
  }
}
</script>

<template>
  <div class="row mt-2">
    <div class="col-12" v-if="fetchedKeeperApplicationData.length > 0">
      <EvaluationKeeperComponent
          :onDeleteRefereeVoteClicked="onDeleteRefereeVoteClicked"
          :items=this.fetchedKeeperApplicationData
      />
    </div>
    <div class="col-12" v-if="fetchedRefereeApplicationData.length > 0">
      <EvaluationRefereeComponent
          :items=this.fetchedRefereeApplicationData
      />
    </div>
  </div>
  <ConfirmationDialogComponent
      :onNegativeButtonClicked="onCancelDeleteRefereeVote"
      :onPositiveButtonClicked="onConfirmDeleteRefereeVoteClicked"
      ref="deleteRefereeVoteConfirmationDialog"
  />
  <CustomNotificationDialog
      ref="customNotificationDialog"
  />
  <LoadingComponent
      :is-loading=this.isLoading
  />
</template>