<script>


import SVGAssetImageComponent from "@/components/SVGAssetImageComponent.vue";

export default {
  components: {SVGAssetImageComponent},
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    getVoterName(item) {
      const voterId = item.id.split('-')[1]
      if (item.homeTeam.id == voterId) {
        return item.homeTeam.name
      }
      return item.awayTeam.name
    },
    getSelectRatings(items) {
      return items.map(item => item.name).join(', ')
    }
  }
}
</script>

<template>
  <div class="row">
    <div class="col-12">
      <table class="table table-sm">
        <thead>
        <tr>
          <th>Phase</th>
          <th>Begegnung</th>
          <th>Schiedsrichter</th>
          <th>Gesamteindruck</th>
          <th>+ Leistung</th>
          <th>- Leistung</th>
          <th>Bewertung durch</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <th>{{ item.phaseId }}</th>
          <td>{{ item.homeTeam.name }} - {{ item.awayTeam.name }}</td>
          <td>{{ item.refereeA.name }} & {{ item.refereeB.name }}</td>
          <td>
            <SVGAssetImageComponent
                :asset-name="'rating' + item.summaryRating"
                max-height="200px"
            />
          </td>
          <td>{{ getSelectRatings(item.goodRatings) }}</td>
          <td>{{ getSelectRatings(item.badRatings) }}</td>
          <td>{{ getVoterName(item) }}</td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>