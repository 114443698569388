<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    }
  }
}
</script>

<template>
  <span class="badge rounded-pill p-2 text-bg-dark me-1 mt-2" v-for="(item, index) in items" :key="item">
       {{ item }}{{ index < items.length - 1 ? ' ' : ' ' }}
  </span>
</template>
