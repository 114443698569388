<script>
export default {
  name: 'BaseBorderButtonComponent',
  props: {
    buttonLabel: {
      type: String,
      required: true
    }
  },
  emits: ['onClicked'],
  methods: {
    onButtonClicked() {
      this.$emit('onClicked');
    }
  }
}

</script>

<template>
  <button
      type="button"
      class="btn btn-outline-secondary w-100 no-radius"
      @click="onButtonClicked">
    {{ this.buttonLabel }}
  </button>
</template>

<style scoped>

.btn-outline-secondary {
  height: 48px;
  border-width: 2px;
  border-color: #20242D;
  color: #20242D;
  text-transform: uppercase;
  font-weight: bold;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #20242D;
  border-color: #20242D;
}
</style>