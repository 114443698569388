<script>
export default {
  props: {
    assetName: {
      type: String,
      required: true,
    },
    altText: {
      type: String,
      default: "Image",
    },
    maxHeight: {
      type: String,
      default: "1000000px",
    },
  },
  data() {
    return {
      resolvedSrc: null,
    };
  },
  computed: {
    styleObject() {
      return {
        '--max-height': this.maxHeight
      }
    },
  },
  async created() {
    try {
      this.resolvedSrc = require(`@/assets/${this.assetName}.svg`);
    } catch (error) {
      this.resolvedSrc = require(`@/assets/DHB_Logo_positiv_RGB.svg`)
    }
  },
};
</script>

<style>
.wow {
  max-height: var(--max-height); ;
  width: auto;
}
</style>

<template>
  <div>
    <img :style="styleObject" class="wow" :src="resolvedSrc" :alt="altText">
  </div>
</template>