<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true,
      default: false
    }
  }
}
</script>

<style>
.fullscreen-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<template>
  <div v-if=this.isLoading class="fullscreen-loading">
    <div class="col-12 text-center mt-4">
      <div class="spinner-grow text-dark" role="status"/>
      <span>&nbsp;&nbsp;&nbsp;</span>
      <div class="spinner-grow text-danger" role="status"/>
      <span>&nbsp;&nbsp;&nbsp;</span>
      <div class="spinner-grow text-warning" role="status"/>
    </div>
  </div>
</template>