<template>
  <div>
    <div
        v-for="(item) in items"
        :key="item.id"
        class="custom-radio-wrapper"
    >
      <label :class="labelClasses(item)">
        <input
            type="radio"
            class="hidden-radio"
            :name="groupName"
            :value="item"
            :disabled="item.disabled"
            v-model="selectedItem"
            @change="onRadioChange"
        />
        <span class="icon-circle"></span>
        {{ item.name }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    groupName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedItem: ''
    };
  },
  computed: {
    labelClasses() {
      return item => ({
        'custom-radio': true,
        'mt-2': true,
        'disabled': item.disabled
      });
    }
  },
  methods: {
    onRadioChange() {
      this.$emit('onVoterSelected', this.selectedItem);
    }
  },
};
</script>

<style scoped>
/* Hide the default radio */
.hidden-radio {
  display: none;
}

/* Style the label to look like a radio button */
.custom-radio {
  position: relative;
  padding-left: 30px; /* Space for the custom radio button */
  cursor: pointer;
  display: inline-block;
  line-height: 20px;
}

/* Style the custom radio */
.icon-circle {
  position: absolute;
  left: 0;
  top: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 50%; /* Make it round */
}

/* Add a checkmark icon when radio is selected */
.hidden-radio:checked + .icon-circle::before {
  content: '\2713'; /* Unicode character for checkmark */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
  font-size: 16px;
}

/* Style for disabled radio */
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
