<script>
export default {
  props: {
    firstLine: {
      type: String,
      required: true
    },
  }
}

</script>

<template>
  <span><b class="preserve-newline">{{ firstLine }}</b></span>
</template>

<style scoped>
.preserve-newline {
  white-space: pre-wrap;
}
</style>