<script>

import BaseButtonComponent from "@/components/button/BaseButtonComponent.vue";
import PillsComponent from "@/components/pills/PillsComponent.vue";

/* global bootstrap */
export default {
  components: {PillsComponent, BaseButtonComponent},
  props: {
    option: {
      type: Object,
      required: true
    },
  },
  methods: {
    onRateButtonClicked() {
      const modal = new bootstrap.Modal(document.getElementById(this.modalId));
      modal.show();
    },
    onConfirmButtonClicked() {
      let modal = bootstrap.Modal.getInstance(document.getElementById(this.modalId));
      modal.hide();
    },
    onCheckBoxChecked() {
      this.$emit('onScaleRatingChanged', {
        type: this.option.type,
        name: this.option.name,
        title: this.option.title,
        checkedItems: this.checkedItems
      });

    },
  },
  data() {
    return {
      modalId: this.option.name,
      checkedItems: []
    }
  }
}
</script>

<style>
.modal-dialog {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

/* Hide the default checkbox */
.hidden-checkbox {
  display: none;
}

/* Style the label to look like a checkbox */
.custom-checkbox {
  position: relative;
  padding-left: 30px; /* Space for the custom checkbox */
  cursor: pointer;
  display: inline-block;
  line-height: 20px;
}

/* Style the custom checkbox */
.icon-box {
  position: absolute;
  left: 0;
  top: 0;
  height: 20px; /* Adjust the size as needed */
  width: 20px; /* Adjust the size as needed */
  background-color: #fff; /* Fill color when not checked */
  border: 2px solid #000;
}

/* Style the custom checkbox when checked */
.hidden-checkbox:checked + .icon-box {
  background-color: #000000; /* Fill color when checked */
}

/* Add a checkmark icon when checked */
.hidden-checkbox:checked + .icon-box::before {
  content: '\2713'; /* Unicode character for checkmark */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff; /* Color of the checkmark */
  font-size: 16px; /* Size of the checkmark */
}
</style>

<template>
  <div class="row mt-2">
    <div class="col-12">
      <b>{{ option.title }}</b>
    </div>
    <div class="col-12 mt-1 mb-1">
      <PillsComponent
          :items=checkedItems
      />
    </div>
    <div class="col-12 mt-2">
      <BaseButtonComponent
          :button-label=option.title
          @onClicked="onRateButtonClicked"
      />
    </div>
    <div class="modal fade" :id=this.modalId tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row">
              <div class="col-10">
                <b>{{ option.title }}</b>
              </div>
              <div class="col-2 text-end">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
              </div>
              <div class="row mt-2" v-for="(item, index) in this.option.values" :key="index">
                <div class="col-12">
                  <label class="custom-checkbox">
                    <input
                        type="checkbox"
                        class="hidden-checkbox mb-2"
                        :id="modalId + 'option' + index"
                        :value="item" v-model="checkedItems"
                        @change="onCheckBoxChecked"
                    />
                    <span class="icon-box"></span>
                    {{ item }}
                  </label>
                </div>
              </div>
              <div class="col-12 mt-4">
                <BaseButtonComponent
                    button-label="Bestätigen"
                    @onClicked="onConfirmButtonClicked"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>