<script>
import {inject} from "vue";
import axios from "axios";
import BaseButtonComponent from "@/components/button/BaseButtonComponent.vue";
import BaseTextarea from "@/components/BaseTextarea.vue";
import CustomNotificationDialog from "@/components/CustomNotificationDialog.vue";
import DoubleLineComponent from "@/components/listitem/DoubleLineComponent.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import RadioGroupComponent from "@/components/checkbox/RadioGroupComponent.vue";
import RatingConfirmationDialogComponent from "@/components/RatingConfirmationDialogComponent.vue";
import ScaleRatingComponent from "@/components/rating/ScaleRatingComponent.vue";
import SelectRatingComponent from "@/components/rating/SelectRatingComponent.vue";
import SingleLineComponent from "@/components/listitem/SingleLineComponent.vue";
import SubmitErrorDialog from "@/components/SubmitErrorDialog.vue";
import SubmitSuccessDialog from "@/components/SubmitSuccessDialog.vue";

export default {
  components: {
    CustomNotificationDialog,
    SubmitErrorDialog,
    SubmitSuccessDialog,
    RatingConfirmationDialogComponent,
    BaseTextarea,
    BaseButtonComponent,
    DoubleLineComponent,
    LoadingComponent,
    RadioGroupComponent,
    ScaleRatingComponent,
    SelectRatingComponent,
    SingleLineComponent,
  },
  setup() {
    const axios = inject('axios')
    return {
      axios: axios
    }
  },
  methods: {
    checkShouldEnableSubmitButton() {
      this.isSubmitButtonDisabled = !this.hasAllRequiredRatingOptionsSelected()
      if (this.shouldShowCommentField) {
        this.isSubmitButtonDisabled = !this.hasCommentLength
      }
      if (!this.isSubmitButtonDisabled) {
        this.isSubmitButtonDisabled = this.voteIsDoneBy == null
      }
      this.createSummaryObject()
    },
    checkShouldShowCommentFieldForKeeper() {
      if (!this.isKeeperApplication || !this.hasAllRequiredRatingOptionsSelected()) return;

      const requiredRatingNames = this.getRequiredRatingOptions().map(option => option.name);
      const ratingSummary = this.ratings.reduce((summary, rating) => {
        if (requiredRatingNames.includes(rating.name)) {
          summary.total += rating.calculatedValue;
          if (!summary.hasAnyRatingLowerThree && rating.value <= 2) {
            summary.hasAnyRatingLowerThree = true;
          }
        }
        return summary;
      }, {total: 0, hasAnyRatingLowerThree: false});

      this.shouldShowCommentField = ratingSummary.total < 10 || ratingSummary.hasAnyRatingLowerThree
    },
    hasDistinctSelectItems() {
      const allCheckItems = this.ratings
          .filter(item => item.type === 'select')
          .flatMap(item => item.checkedItems);
      return allCheckItems.length === new Set(allCheckItems).size;
    },
    getCommonSelectItems() {
      const selectItems = this.ratings.filter(item => item.type === 'select').map(item => item.checkedItems);

      if (selectItems.length === 0) return '';

      const commonItems = selectItems[0].filter(item => selectItems.every(sItem => sItem.includes(item))).map(value => `<b>${value}</b>`);

      return commonItems.join(',<br/>');
    },
    getRequiredRatingOptions() {
      return this.ratingOptions.filter(v => v.required);
    },
    extractNamesFrom(arr) {
      return arr.flatMap(v => v.name).sort();
    },
    hasAllRequiredRatingOptionsSelected() {
      const requiredRatingsNames = this.extractNamesFrom(this.getRequiredRatingOptions());
      const givenRatingsNames = this.extractNamesFrom(this.ratings);
      return requiredRatingsNames.every(item => givenRatingsNames.includes(item));
    },
    onSubmitButtonClicked() {
      if (this.isRefereeApplication && !this.hasDistinctSelectItems()) {
        this.$refs.customNotificationDialog.show({
          title: "Identische Auswahl",
          message: `<p>In der Kategorie <b>Bewertung 'Gute Leistung'</b> & <b>Bewertung 'Schlechte Leistung'</b> hast du identische Kriterien ausgewählt:</p> ${this.getCommonSelectItems()}`,
          buttonLabel: "Bearbeiten"
        })
        return
      }
      this.showConfirmationDialog()
    },
    onScaleRating(rating) {
      this.addRating(rating)
    },
    onSelectRating(rating) {
      this.addRating(rating)
    },
    onSubmitRating() {
      const submitBody = {
        phaseId: this.phaseId,
        matchId: this.matchId,
        voterId: this.voteIsDoneBy.id,
        type: this.type,
        ratings: this.ratings
            .map(value => {
              if (value.type === 'scale') {
                return {
                  name: value.name,
                  value: value.value
                }
              }
              if (value.type === 'select') {
                return {
                  name: value.name,
                  items: value.checkedItems
                }
              }
            }),
        comment: this.commentInput
      }
      this.isLoading = true
      axios.post('/rate', submitBody)
          .then(value => {
            if (value.data.success) {
              this.showSubmitSuccess()
              localStorage.setItem('token', null)
            }
          })
          .catch(() => {
            this.showSubmitError()
          })
    },
    addRating(rating) {
      const index = this.ratings.findIndex(obj => obj.name === rating.name);
      if (index !== -1) {
        this.ratings[index] = rating;
      } else {
        this.ratings.push(rating);
      }
      this.checkShouldShowCommentFieldForKeeper()
      this.checkShouldEnableSubmitButton()
    },
    onVoterSelected(value) {
      this.voteIsDoneBy = value;
      this.checkShouldShowCommentFieldForKeeper()
      this.checkShouldEnableSubmitButton()
    },
    createVoterOptions() {
      if (this.isKeeperApplication) {
        this.referees.disabled = this.hasVoteBy.refereeA && this.hasVoteBy.refereeB
        this.voters = [
          this.referees
        ]
        if (this.delegate != null) {
          this.delegate.disabled = this.hasVoteBy.delegate
          this.voters.push(this.delegate)
        }
      }
      if (this.isRefereeApplication) {
        this.homeTeam.disabled = this.hasVoteBy.homeTeam
        this.awayTeam.disabled = this.hasVoteBy.awayTeam
        this.voters = [
          this.homeTeam,
          this.awayTeam
        ]
      }
    },
    onCommentChanged(input) {
      const inputCopy = (' ' + input).slice(1);
      const cleanInputLength = inputCopy.replace(/\s+/g, '').length
      this.hasCommentLength = cleanInputLength > 25
      this.commentInput = input
      this.checkShouldEnableSubmitButton()
    },
    showConfirmationDialog() {
      this.createSummaryObject()
      this.$refs.confirmationDialog.show()
    },
    createSummaryObject() {
      let toRate = {
        name: this.ratingPersons,
        role: this.ratingRole,
      }
      let ratingFrom = {
        name: "",
        role: ""
      }
      if (this.voteIsDoneBy != null) {
        ratingFrom = {
          name: this.voteIsDoneBy.name,
          role: this.voteIsDoneBy.role
        }
      }
      this.summaryRating = {
        toRate: toRate,
        ratings: [...this.ratings].sort((a, b) => a.type - b.type),
        comment: this.commentInput,
        rating: this.voteIsDoneBy,
        ratingFrom: ratingFrom
      }
    },
    showSubmitSuccess() {
      this.isLoading = false
      this.$refs.submitSuccessDialog.show()
    },
    showSubmitError() {
      this.isLoading = false
      this.$refs.submitErrorDialog.show()
    },
  },
  data() {
    return {
      isCoachApplication: false,
      isKeeperApplication: false,
      isRefereeApplication: false,
      type: "",
      phaseId: -1,
      matchId: -1,
      isLoading: true,
      phaseName: "",
      initialScaleRatingValue: -1,

      isSubmitButtonDisabled: true,

      commentDescriptionRequired: "Aufrund deiner Bewertung ist eine Begründung notwendig und muss mindesten 25 Zeichen umfassen.",
      commentDescriptionDefault: "Du kannst einen Kommentar zur Leistung von Zeitnehmer & Sekretär hinterlassen.",

      shouldShowCommentField: false,
      commentInput: "",
      hasTeamVotes: false,
      hasRefereeVotes: false,
      hasCommentLength: false,

      teams: "",
      result: "",

      homeTeam: {
        id: "",
        name: ""
      },
      awayTeam: {
        id: "",
        name: ""
      },
      referees: {
        id: "",
        name: "",
      },
      keepers: {
        id: "",
        name: ""
      },
      delegate: null,

      hasVoteBy: {},

      ratingOptions: [],

      ratings: [],

      ratingPersons: "",
      ratingRole: "",

      voteIsDoneBy: null,
      voters: [],

      summaryRating: {
        toRate: {
          name: "",
          role: "",
        },
        comment: "",
        ratings: [],
        ratingFrom: {
          name: "",
          role: ""
        }
      },

      submitRateKeeperBody: {
        phaseId: -1,
        matchId: -1,
        voterId: -1,
        techniqueRating: -1,
        cooperationRating: -1,
        appearanceRating: -1,
        comment: ""
      }
    }
  },
  mounted() {
    const segments = this.$route.fullPath.split("/")
    this.type = segments[1].toLocaleLowerCase()
    this.phaseId = segments[3]
    this.matchId = segments[5]
    this.isCoachApplication = this.type === 'coach'
    this.isKeeperApplication = this.type === 'kampfgericht'
    this.isRefereeApplication = this.type === 'schiedsrichter'

    axios.get(`type/${this.type}/${this.phaseId}/match/${this.matchId}`)
        .then(value => {
          this.isLoading = false;
          const match = value.data.value.match

          this.phaseName = value.data.name;
          this.teams = `${match.homeTeam.name} : ${match.awayTeam.name}`;
          this.result = `${match.result} (${match.halftimeResult})`;

          this.homeTeam = match.homeTeam
          this.homeTeam.role = "Heimmannschaft"
          this.awayTeam = match.awayTeam
          this.awayTeam.role = "Gastmannschafft"
          this.referees = {
            id: `${match.refereeA.id}`,
            name: `${match.refereeA.name} & ${match.refereeB.name}`,
            role: "Schiedsrichter"
          }
          this.keepers = {
            id: `${match.timekeeper.id}-${match.scorekeeper.id}`,
            name: `${match.timekeeper.name} & ${match.scorekeeper.name}`,
            role: "Kampfgericht"
          }
          if (match.delegate != null) {
            this.delegate = {
              id: match.delegate.id,
              name: match.delegate.name,
              role: "Delegierter"
            }
          }
          if (this.isRefereeApplication) {
            this.ratingPersons = this.referees.name
            this.ratingRole = this.referees.role
            this.hasTeamVotes = match.hasVoteBy.homeTeam && match.hasVoteBy.awayTeam
            this.hasRefereeVotes = false
          }
          if (this.isKeeperApplication) {
            this.ratingPersons = this.keepers.name
            this.ratingRole = this.keepers.role
            this.initialScaleRatingValue = 4
            this.hasTeamVotes = false
            this.hasRefereeVotes = match.hasVoteBy.refereeA && match.hasVoteBy.refereeB && (!match.delegate || match.hasVoteBy.delegate);
          }

          this.hasVoteBy = match.hasVoteBy

          return axios.get(`/ratingoptions/${this.type}`)
        })
        .then(rationsOptions => {
          this.ratingOptions = rationsOptions.data.values
          this.createVoterOptions()
        })
        .catch((e) => {
          if (e.message.includes('401')) {
            this.$router.replace(`/login/${this.type}/liga/${this.phaseId}/spiel/${this.matchId}`)
          } else {
            this.showSubmitError()
          }
        });
  }
}
</script>

<template>
  <div class="row mt-2">
    <div class="col-12">
      {{ this.phaseName }}
    </div>
    <div class="col-12 mt-3">
      <DoubleLineComponent
          :first-line=this.teams
          second-line="Heim : Gast"
      />
    </div>
    <div class="col-12 mt-3">
      <DoubleLineComponent
          :first-line=this.result
          second-line="Ergebnis"
      />
    </div>
    <div class="col-12 mt-2">
      <DoubleLineComponent
          :first-line=this.ratingPersons
          :second-line=this.ratingRole
      />
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12 mt-2" v-for="(ratingOption, index) in this.ratingOptions" :key="index">
      <SelectRatingComponent
          :ref=ratingOption.name
          v-if="ratingOption.type === 'select'"
          :option="ratingOption"
          @onScaleRatingChanged="onSelectRating"
      />

      <ScaleRatingComponent
          :ref=ratingOption.name
          v-if="ratingOption.type === 'scale'"
          :option="ratingOption"
          :initialValue="initialScaleRatingValue"
          @onScaleRating="onScaleRating"
      />
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12">
      <BaseTextarea
          description="Kommentar:"
          :onInputChanged="onCommentChanged"
      />
      <p v-if="this.shouldShowCommentField">{{ this.commentDescriptionRequired }}</p>
      <p v-else>{{ this.commentDescriptionDefault }}</p>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12">
      <SingleLineComponent
          first-line="Bewertung durch"/>
    </div>
    <div class="col-12 mt-2">
      <RadioGroupComponent
          :items=this.voters
          id="id"
          group-name="myRadioGroup"
          @onVoterSelected="onVoterSelected"
      />
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12" v-if="hasTeamVotes || hasRefereeVotes">
      <SingleLineComponent
          first-line="Für diese Begegnung kan keine Bewertung mehr abgegeben werden!"/>
    </div>
    <div v-else class="col-12">
      <BaseButtonComponent
          button-label="Absenden"
          @onClicked="onSubmitButtonClicked"
          :disabled="isSubmitButtonDisabled"
      />
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12">
      <span>Bewertungen die mit * gekennzeichnet sind Pflichtangaben</span>
    </div>
  </div>
  <CustomNotificationDialog
      ref="customNotificationDialog"
  />
  <LoadingComponent
      :is-loading=this.isLoading
  />
  <RatingConfirmationDialogComponent
      :summary=this.summaryRating
      ref="confirmationDialog"
      @onSubmitRating="onSubmitRating"
  />
  <SubmitSuccessDialog
      ref="submitSuccessDialog"
  />
  <SubmitErrorDialog
      ref="submitErrorDialog"
  />
</template>