<script>

import {inject} from "vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import CustomNotificationDialog from "@/components/CustomNotificationDialog.vue";
import BaseButtonComponent from "@/components/button/BaseButtonComponent.vue";

export default {
  components: {
    BaseButtonComponent,
    CustomNotificationDialog,
    LoadingComponent
  },
  setup() {
    const axios = inject('axios')
    return {
      axios: axios
    }
  },
  data() {
    return {
      isSubmitButtonDisabled: true,
      isLoading: false,
      application: "",
      username: "",
      password: ""
    }
  },
  methods: {
    checkShouldEnableSubmitButton() {
      this.isSubmitButtonDisabled = !(this.username.length >= 3 && this.password.length >= 3 && this.application.length > 3)
    },
    onUsernameInputChanged(event) {
      this.username = event.target.value
      this.checkShouldEnableSubmitButton()
    },
    onPasswordInputChanged(event) {
      this.password = event.target.value
      this.checkShouldEnableSubmitButton()
    },
    onSubmitButtonClicked() {
      this.axios
          .post('/login', {
            application: this.application,
            username: this.username,
            password: this.password,
            source: 'eval',
          })
          .then(response => {
            localStorage.setItem('token', response.data.value.token)
            this.$router.replace(`/evaluation/${this.application}`)
          })
          .catch(reason => {
            this.$refs.customNotificationDialog.show({
              title: "Oh, Oh!",
              message: reason.response.data.message,
              buttonLabel: "Alles klar!"
            })
          })
    }
  },
  mounted() {
    localStorage.removeItem('token')
    const fullPathSplit = this.$route.fullPath.split('/')
    if (fullPathSplit.length > 3) {
      this.application = fullPathSplit[2]
    }
  }
}
</script>

<template>

  <div class="row">
    <div class="col-12 mt-4">
      <input
          class="form-control"
          placeholder="Benutzername"
          style="height: 48px"
          type="text"
          @input="onUsernameInputChanged"
      />
    </div>
    <div class="col-12 mt-4">
      <input
          class="form-control"
          placeholder="Passwort"
          style="height: 48px"
          type="password"
          @input="onPasswordInputChanged"
      />
    </div>
    <div class="col-12 mt-3">
      <BaseButtonComponent
          :disabled=this.isSubmitButtonDisabled
          button-label="Login"
          @onClicked=onSubmitButtonClicked
      />
    </div>
  </div>
  <CustomNotificationDialog
      ref="customNotificationDialog"
  />
  <LoadingComponent
      :is-loading=this.isLoading
  />
</template>

<style scoped>

</style>
