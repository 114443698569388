<script>
import ImageTitleGridComponent from "@/components/imageTItleGrid/ImageTitleGridComponent.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import {inject} from "vue";

export default {
  components: {
    LoadingComponent,
    ImageTitleGridComponent
  },
  setup() {
    const axios = inject('axios')
    return {
      axios: axios
    }
  },
  mounted() {
    this.axios
        .get(`/`)
        .then(response => {
          this.isLoading = false
          this.items = response.data.values.map(value => {
            let link = ""
            let title = ""
            if (value.type === "zns") {
              title = "Kampfgericht"
              link = "/kampfgericht"
              this.goToKampfgericht()
            } else if (value.type === "referee") {
              title = "Schiedsrichter"
              link = "schiedsrichter"
            } else if (value.type === "coach") {
              title = "Coach"
              link = "coach"
            }
            return {
              svgName: "",
              title: title,
              link: link
            }
          })
        })
        .catch(reason => {
          reason.message
          // todo handle error
        })
  },
  data() {
    return {
      isLoading: true,
      items: []
    }
  },
  methods: {
    goToKampfgericht(){
      this.$router.replace("/kampfgericht")
    }
  }
}
</script>

<template>
  <ImageTitleGridComponent
      :items=items
  />

  <LoadingComponent
      :is-loading="this.isLoading"
  />
</template>