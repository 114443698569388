<script>

import SVGAssetImageComponent from "@/components/SVGAssetImageComponent.vue";

export default {
  components: {
    SVGAssetImageComponent
  },
  props: {
    title: {
      type: String,
      required: true
    },
    raw: {
      type: Number,
      required: true
    },
    weighted: {
      type: Number,
      required: true
    },
  }
}
</script>

<template>
  <div class="row">
    <div class="col-12 text-center"><b>{{ this.title }}</b></div>
    <div class="col-12 text-center mt-1">
      <SVGAssetImageComponent
          :asset-name="'rating' + this.raw"/>
    </div>
    <div class="col-12 text-center mt-1">
      {{ this.weighted }}
    </div>
  </div>
</template>