<script>
import ImageTitleGridComponent from "@/components/imageTItleGrid/ImageTitleGridComponent.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import {inject} from "vue";

export default {
  components: {
    LoadingComponent,
    ImageTitleGridComponent
  },
  setup() {
    const axios = inject('axios')
    return {
      axios: axios
    }
  },
  mounted() {
    this.axios
        .get(`/overview`, {
          params: this.$route.query
        })
        .then(response => {
          this.updatePhases(response.data.values)
        })
        .catch(() => {
          // todo handle error
        })
  },
  data() {
    return {
      applicationType: "",
      isLoading: true,
      items: []
    }
  },
  methods: {
    serializeQuery(query) {
      const params = new URLSearchParams();
      for (let key in query) {
        params.append(key, query[key]);
      }
      return params.toString();
    },
    updatePhases(phases) {
      this.items = phases.map(value => {
        return {
          svgName: `${value.tournamentId}`,
          title: value.name,
          link: `${this.$route.path}/liga/${value.id}?${this.serializeQuery(this.$route.query)}`
        }
      })
      this.isLoading = false
    }
  }
}
</script>

<template>
  <ImageTitleGridComponent
      :items=items
  />
  <LoadingComponent
      :is-loading=this.isLoading
  />
</template>