import axios from 'axios';

let additionalHeaders = {};
axios.defaults.baseURL = 'https://dhb-feedback.de/api'
axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token')
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        for (let key in additionalHeaders) {
            config.headers[key] = additionalHeaders[key];
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

function addApplicationTypeHeader(value) {
    additionalHeaders['applicationType'] = value;
}

export {axios as default, addApplicationTypeHeader};
