<script>
/* global bootstrap */
export default {
  data() {
    return {
      modal: null
    }
  },
  methods: {
    show() {
      this.modal.show()
    }
  },
  mounted() {
    this.modal = new bootstrap.Modal(this.$refs.modal, {
      backdrop: 'static',
      keyboard: false
    });
  }
}

</script>

<template>
  <div id="modal" ref="modal" class="modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen d-flex align-items-center justify-content-center"
         style="background: #20242D">
      <div class="row">
        <div class="col-12  d-flex align-items-center justify-content-center">
          <span class="circleSuccess">
            <i class="bi bi-check-lg"></i>
          </span>
        </div>
        <div class="col-12 mt-4 d-flex align-items-center justify-content-center">
          <h3 style="color: white">Deine Angaben wurden gespeichert!</h3>
        </div>
        <div class="col-12 mt-4 d-flex align-items-center justify-content-center">
          <p style="color: white">Du kannst das Fenster nun schließen</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style>

.circleSuccess {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #EFCB4F;
  width: 136px;
  height: 136px;
  border-radius: 100px;
  padding: 5px 8px;
  gap: 10px;
}

.bi-check-lg {
  margin-top: 1vh;
  font-size: 8rem;
}

</style>