<script>
/* global bootstrap */
import BaseBorderButtonComponent from "@/components/button/BaseBorderButtonComponent.vue";
import BaseButtonComponent from "@/components/button/BaseButtonComponent.vue";
import DoubleLineComponent from "@/components/listitem/DoubleLineComponent.vue";
import PillsComponent from "@/components/pills/PillsComponent.vue";
import SingleLineComponent from "@/components/listitem/SingleLineComponent.vue";
import SVGAssetImageComponent from "@/components/SVGAssetImageComponent.vue";

export default {
  components: {
    SVGAssetImageComponent,
    DoubleLineComponent,
    BaseBorderButtonComponent,
    PillsComponent,
    SingleLineComponent,
    BaseButtonComponent

  },
  data() {
    return {
      modal: null
    }
  },
  props: {
    summary: {
      type: Object,
      required: true
    }
  },
  methods: {
    hide() {
      this.modal.hide()
    },
    onSubmitClicked() {
      this.$emit('onSubmitRating')
      this.hide()
    },
    onEditClicked() {
      this.hide()
    },
    show() {
      this.modal.show();
    }
  },
  mounted() {
    this.modal = new bootstrap.Modal(document.getElementById('confirmationDialog'));
  }
}
</script>

<template>
  <div class="modal fade" id="confirmationDialog" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col-10">
              <b>Überprüfe deine Eingabe</b>
            </div>
            <div class="col-2 text-end">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <SingleLineComponent
                    first-line="Bewertung der Leistung von:"
                />
              </div>
              <div class="col-12 mt-2 mb-2">
                <DoubleLineComponent
                    :first-line=this.summary.toRate.name
                    :second-line=this.summary.toRate.role
                />
              </div>
              <div class="col-12 mt-2" v-for="(rating) in this.summary.ratings" :key="rating">
                <div class="col-12">
                  <SingleLineComponent
                      :first-line="rating.title"
                  />
                </div>
                <div class="row" v-if="rating.type === 'scale'">
                  <div class="col-12 mt-2">
                    <SVGAssetImageComponent
                        :asset-name="'rating' + rating.value"
                    />
                  </div>
                </div>
                <div class="row" v-if="rating.type === 'select'">
                  <div class="col-12 mt-2 mb-2">
                    <PillsComponent
                        :items=rating.checkedItems
                    />
                  </div>
                </div>
              </div>
              <div class="row mt-2" v-if="this.summary.comment.length > 0">
                <div class="col-12">
                  <SingleLineComponent
                      first-line="Begründung"
                  />
                </div>
                <div class="col-12">
                  <span><i>{{ this.summary.comment }}</i></span>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <SingleLineComponent
                    first-line="Bewertung durch:"
                />
              </div>
              <div class="col-12 mt-2">
                <DoubleLineComponent
                    :first-line=this.summary.ratingFrom.name
                    :second-line=this.summary.ratingFrom.role
                />
              </div>
            </div>
            <div class="col-12 mt-4">
              <BaseButtonComponent
                  button-label="absenden"
                  @onClicked="onSubmitClicked"
              />
              <BaseBorderButtonComponent
                  class="mt-2"
                  button-label="bearbeiten"
                  @onClicked="onEditClicked"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.modal-dialog {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
</style>