<script>

import MatchListItemComponent from "@/components/MatchListItemComponent.vue";

export default {
  components: {MatchListItemComponent},
  props: {
    items: {
      type: Array,
      required: true
    }
  }
}
</script>

<template>
  <div class="row mt-2">
    <div class="col-12" v-for="(item, index) in items" :key="index">
      <MatchListItemComponent
          :first-line=item.firstLine
          :second-line=item.secondLine
          :link=item.link
      />
    </div>
  </div>
</template>