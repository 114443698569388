<template>
  <div class="row">
    <div class="col-12">
      <b>{{ description}}</b>
    </div>
    <div class="col-12 mt-2">
    <textarea
        class="form-control"
        :value="inputValue"
        @input="handleInput"
        placeholder="Enter text here"
        rows="4"
    />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    description: {
      type: String,
      required: false
    },
    onInputChanged: {
      type: Function,
      default: () => {
      }
    }
  },
  data() {
    return {
      inputValue: ''
    };
  },
  methods: {
    handleInput(event) {
      this.onInputChanged(event.target.value)
    }
  }
};
</script>

<style scoped>
textarea.form-control {
  width: 100%;
}
</style>
