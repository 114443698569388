<script>
import CopyrightComponent from "@/components/CopyrightComponent.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";

export default {
  name: 'QuickResponse',
  components: {
    CopyrightComponent,
    HeaderComponent
  },
  computed: {
    showBackIcon() {
      return window.history.length > 2
    },
  }
}
</script>

<template>
  <header class="container">
    <div class="row">
      <div class="col-12">
        <HeaderComponent/>
      </div>
    </div>

  </header>

  <main role="main" class="container">
    <div class="row">
      <div class="col-12">
        <router-view></router-view>
      </div>
    </div>
  </main>

  <footer class="footer pt-5">
    <div class="container">
      <CopyrightComponent/>
    </div>
  </footer>
</template>