<script>
import SVGAssetImageComponent from "@/components/SVGAssetImageComponent.vue";

export default {
  components: {SVGAssetImageComponent,},
  computed: {
    showBackIcon() {
      return window.history.length > 2 && this.$route.fullPath !== '/'
    },
  },
  methods: {
    goBack() {
      window.history.back();
    },
    navigateToRoot() {
      this.$router.replace('/')
    }
  }
}
</script>

<template>
  <div class="row">
    <div class="col-12 mt-2" v-if="showBackIcon">
      <i class="bi bi-arrow-left" @click="goBack"/>
    </div>
    <div class="col-12 mt-4 text-center image">
      <SVGAssetImageComponent
          style="cursor: pointer;"
          asset-name="DHB_Logo_positiv_RGB"
          max-height="200px"
          @click="navigateToRoot"
      />
    </div>
    <div class="col-12">
      <h5><b>Instant Feedback</b></h5>
      <div class="col-12 applicationNameDivider"/>
    </div>
  </div>
</template>

<style>
.applicationNameDivider {
  border-bottom: 2px solid #20242D;
  margin-top: 4px;
  margin-bottom: 4px;
}

.bi-arrow-left {
  font-size: 2rem;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
}

.bi-arrow-left:hover {
  color: #e52f3d;
  transform: scale(1.1);
}
</style>