import App from './App.vue'
import {createApp} from 'vue/dist/vue.esm-bundler';
import {createRouter, createWebHistory} from "vue-router";
import NotFoundComponent from "@/components/NotFoundComponent.vue"
import axiosClient from './axiosClient'
import PhasesOverviewComponent from "@/components/PhasesOverviewComponent.vue";
import RootComponent from "@/components/RootComponent.vue";
import ApplicationTypeContainerComponent from "@/components/ApplicationTypeContainerComponent.vue";
import PhaseOverviewComponent from "@/components/PhaseOverviewComponent.vue";
import MatchDetailComponent from "@/components/MatchDetailComponent.vue";
import LoginComponent from "@/components/LoginComponent.vue";
import EvaluationComponent from "@/components/evaluation/EvaluationComponent.vue";
import EvaluationLoginComponent from "@/components/evaluation/EvaluationLoginComponent.vue";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: RootComponent
        },
        {
            path: '/coach',
            component: ApplicationTypeContainerComponent,
            children: getChildren()
        },
        {
            path: '/kampfgericht',
            component: ApplicationTypeContainerComponent,
            children: getChildren()
        },
        {
            path: '/schiedsrichter',
            component: ApplicationTypeContainerComponent,
            children: getChildren()
        },
        {
            path: '/login/:type/liga/:ligaId/spiel/:spielId',
            component: LoginComponent,
        },
        {
            path: '/evaluation',
            component: ApplicationTypeContainerComponent,
            children: getEvaluationChildren()
        },
        {
            path: '/:catchAll(.*)',
            component: NotFoundComponent
        }
    ]
})


function getEvaluationChildren() {
    return [
        {
            path: '',
            component: EvaluationComponent
        },
        {
            path: 'kampfgericht',
            component: EvaluationComponent
        },
        {
            path: 'schiedsrichter',
            component: EvaluationComponent
        },
        {
            path: 'kampfgericht/login',
            component: EvaluationLoginComponent
        },
        {
            path: 'schiedsrichter/login',
            component: EvaluationLoginComponent
        }
    ]
}

function getChildren() {
    return [
        {
            path: '',
            component: PhasesOverviewComponent,
        },
        {
            path: 'liga/:ligaId',
            component: PhaseOverviewComponent,
        },
        {
            path: 'liga/:ligaId/spiel/:spielId',
            component: MatchDetailComponent,
        }
    ];
}

router.afterEach((to) => {
    document.title = to.meta.title || 'Instant Feedback';
});
router.beforeEach((to, from, next) => {
    console.log(`Navigating from ${from.fullPath} to ${to.fullPath}`);
    next();
});

const app = createApp(App)
app.provide('axios', axiosClient)
app.use(router)
app.mount('#app')