<script>

import DoubleLineComponent from "@/components/listitem/DoubleLineComponent.vue";

export default {
  name: "MatchListItemComponent",
  components: {DoubleLineComponent},
  props: {
    firstLine: {
      type: String,
      required: true
    },
    secondLine: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    }
  }
}
</script>

<style>
.circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #EFCB4F;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  padding: 5px 8px;
  gap: 10px;
}
</style>

<template>
  <div class="row align-items-center position-relative">
    <div class="col-11">
      <DoubleLineComponent
          :first-line=firstLine
          :second-line=secondLine
      />
    </div>
    <div class="col-1 d-flex flex-column justify-content-start align-items-end">
      <span class="circle d-flex flex-column justify-content-start"><i class="bi bi-chevron-right"></i></span>
    </div>
    <a class="stretched-link" :href=link></a>
  </div>
  <hr class="mt-3"/>
</template>