<template>
  <div class="row">
    <div class="col-12 text-center">
      <p>DEUTSCHER HANDBALLBUND E. V. 2024 -
        <a href="https://www.dhb-schiedsrichterportal.de/impressum/" target="_blank">Impressum</a>
        <span> | </span>
        <a href="https://www.dhb-schiedsrichterportal.de/datenschutzerklaerung/" target="_blank">Datenschutz</a>
      </p>
    </div>
  </div>
</template>
