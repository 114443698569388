<template>
  <div class="row" v-if="isCalculated">
    <div class="col-12 mt-2 mb-2">
      <p><b>Zeitraumfilter</b></p>
      <DateRangePicker
          :min-timestamp="minTimestamp"
          :max-timestamp="maxTimestamp"
          :on-date-selected="onDateRangeSelected"
      />
    </div>
    <div class="col-12 mb-4">
      <button type="button" class="btn btn-outline-primary" @click="onCSVExportClicked">
        <i class="bi bi-download"/> CSV Export
      </button>
    </div>
  </div>

  <div class="row" v-if="isEvaluationListVisible">
    <div class="col-12">
      <div class="row" v-for="(phase) in phases" :key="phase.phaseId">
        <div class="col-12">
          <h4 class="mb-4">{{ phase.phaseName.shortName }}</h4>
        </div>

        <div class="col-12 ps-4 pe-4" v-for="(evaluation) in phase.matches" :key="evaluation.id">
          <EvaluationKeeperItemComponent
              :onDeleteRefereeVoteClicked="onDeleteRefereeVoteClicked"
              :evaluation="evaluation"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import createKeeperCSVContent from "../../usecase/CreateKeeperCSVContentUseCase"
import DateRangePicker from "@/components/DateRangePicker.vue";
import EvaluationKeeperItemComponent from "@/components/evaluation/EvaluationKeeperItemComponent.vue";

export default {
  components: {
    DateRangePicker,
    EvaluationKeeperItemComponent,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    onDeleteRefereeVoteClicked: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      phases: [],
      minTimestamp: 0,
      maxTimestamp: 0,
      isCalculated: false,
      isEvaluationListVisible: false,
      csvContent: null
    };
  },
  watch: {
    phases: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          // csv creation
          this.csvContent = createKeeperCSVContent.execute(newValue)
        }
      },
    },
  },
  methods: {
    findOldestAndNewestTimestamp(arr) {
      let newest = 0
      let oldest = 0
      if (!arr || arr.length === 0) {
        return {newest, oldest};
      }
      newest = Math.max(...arr);
      oldest = Math.min(...arr);
      return {newest, oldest};
    },
    onCSVExportClicked() {
      const blob = new Blob([this.csvContent], {type: 'text/csv;charset=utf-8'});
      const url = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = 'kampfgericht-export.csv';
      downloadLink.click();
      window.URL.revokeObjectURL(url);
    },
    onDateRangeSelected(range) {
      this.isEvaluationListVisible = false
      const deepCopyOfItems = JSON.parse(JSON.stringify(this.items));
      if (range !== null) {
        const start = new Date(range[0]);
        start.setHours(0, 0, 0, 0);
        const end = new Date(range[1]);
        end.setHours(23, 59, 59, 59);

        this.phases = Array.from(deepCopyOfItems).filter((item) => {
          item.matches = item.matches.filter((match) => {
            return (match.timestamp >= start.getTime() && match.timestamp <= end.getTime());
          });

          return item.matches.length > 0;
        });
      } else {
        this.phases = deepCopyOfItems;
      }
      this.isEvaluationListVisible = true
    },
  },
  mounted() {
    if (!this.isCalculated) {
      const timestamps = new Set(this.items.flatMap(item => item.matches.map(match => match.timestamp)));
      const {newest, oldest} = this.findOldestAndNewestTimestamp(timestamps)
      this.minTimestamp = oldest
      this.maxTimestamp = newest
      this.isCalculated = true;
    }

    this.phases = this.items;
    this.isEvaluationListVisible = true
  },
};
</script>
