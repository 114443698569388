<template>
  <button
      type="button"
      class="btn btn-dark w-100 no-radius"
      @click="onButtonClicked"
      :disabled="disabled"
  >
    {{ buttonLabel }}
  </button>
</template>

<script>
export default {
  props: {
    buttonLabel: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['onClicked'],
  methods: {
    onButtonClicked() {
      if (!this.disabled) {
        this.$emit('onClicked');
      }
    }
  }
}
</script>

<style scoped>
.btn-dark {
  height: 48px;
  border-width: 2px;
  border-color: #20242D;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
}

.btn-dark:hover {
  color: #20242D;
  background-color: #fff;
  border-color: #20242D;
}

.btn-dark:disabled {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(32, 36, 45, 0.5);
  background-color: rgba(32, 36, 45, 0.5);
  cursor: not-allowed;
}

</style>