<template>
  <div class="row">
    <div class="col-12">
      <VueDatePicker
          class="datePicker"
          v-model="dateRange"
          :min-date="minDate"
          :max-date="maxDate"
          :start-date="maxDate"
          range
          prevent-min-max-navigation
          :clearable="false"
          locale="de"
          :format="'dd.MM.yyyy'"
          :initial-view="initialViewDate"
          :enable-time-picker="false"
          select-text="Auswählen"
          cancel-text="Zurück"
          @update:modelValue="updateDateRange"
      />
    </div>
    <div class="col-12 mt-2">
      <button
          class="btn btn-outline-danger reset-button"
          v-if="dateRange.start !== null && dateRange.end !== null"
          @click="resetDateFilter"
      >Zurücksetzen
      </button>
    </div>
  </div>
</template>

<style scoped>
.datePicker, border, border-black, .rounded > div > div > input {
  border: 1px solid black !important;
}

.reset-button {
  cursor: pointer;
}
</style>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  components: {
    VueDatePicker
  },
  props: {
    minTimestamp: {
      type: Number,
      default: () => Date.now()
    },
    maxTimestamp: {
      type: Number,
      default: () => Date.now() + (365 * 24 * 60 * 60 * 1000)
    },
    onDateSelected: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      dateRange: {
        start: null,
        end: null
      },
      initialViewDate: new Date(this.maxTimestamp)
    };
  },
  computed: {
    minDate() {
      return new Date(this.minTimestamp);
    },
    maxDate() {
      return new Date(this.maxTimestamp);
    }
  },
  methods: {
    resetDateFilter() {
      this.updateDateRange(null);
      this.dateRange = {start: null, end: null};
    },
    updateDateRange(value) {
      this.dateRange = value;

      if (this.onDateSelected) {
        this.onDateSelected(this.dateRange);
      }
    }
  }
};
</script>
