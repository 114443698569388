<script>
import EvaluationRatingItemComponent from "@/components/evaluation/EvaluationRatingItemComponent.vue";

export default {
  components: {
    EvaluationRatingItemComponent
  },
  props: {
    evaluation: {
      type: Object,
      required: true
    },
    onDeleteRefereeVoteClicked: {
      type: Function,
      required: true
    }
  }
}
</script>

<template>
  <div class="row border border-black shadow-lg bg-body-tertiary rounded mb-4">
    <div class="col-11 mt-2">
      {{ evaluation.homeTeam.name }} - {{ evaluation.awayTeam.name }}
    </div>
    <div class="col-1 mt-2 text-center">
      <i class="bi bi-trash-fill" @click="this.onDeleteRefereeVoteClicked(evaluation)"></i>
    </div>
    <div class="col-12">
      <small>
        {{ new Date(evaluation.timestamp).getDate() }}.{{
          new Date(evaluation.timestamp).getMonth() + 1
        }}.{{ new Date(evaluation.timestamp).getFullYear() }}
      </small> // <span class="badge rounded-pill text-bg-danger" v-if="evaluation.isVoteByDelegate">{{
        evaluation.delegate.name
      }}</span>
      <span class="badge rounded-pill text-bg-success" v-if="!evaluation.isVoteByDelegate">{{
          evaluation.refereeA.name
        }} & {{ evaluation.refereeB.name }}</span>
    </div>
    <div class="col-12 mt-2 mb-2">
      <b>{{ evaluation.timekeeper.name }} / {{ evaluation.scorekeeper.name }}</b>
    </div>
    <div class="col-3">
      <EvaluationRatingItemComponent
          title="ST"
          :weighted=evaluation.ratings.technique.weighted
          :raw=evaluation.ratings.technique.raw
      />
    </div>
    <div class="col-3">
      <EvaluationRatingItemComponent
          title="ZA"
          :weighted=evaluation.ratings.teamwork.weighted
          :raw=evaluation.ratings.teamwork.raw
      />
    </div>
    <div class="col-3">
      <EvaluationRatingItemComponent
          title="PA"
          :weighted=evaluation.ratings.appearance.weighted
          :raw=evaluation.ratings.appearance.raw
      />
    </div>
    <div class="col-3">
      <EvaluationRatingItemComponent
          title="SUM"
          :weighted=evaluation.ratings.sum.weighted
          :raw=Math.round(evaluation.ratings.average.raw)
      />
    </div>
    <div class="col-12 mt-2 mb-2">
      <span v-if="evaluation.comment.length > 1">Kommentar:<br/></span>
      {{ evaluation.comment }}
    </div>
  </div>
</template>