<script>
import ImageTitleGridItemComponent from "@/components/imageTItleGrid/ImageTitleGridItemComponent.vue";

export default {
  components: {
    ImageTitleGridItemComponent
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
}
</script>

<template>
  <div class="row">
    <div class="col-6 col-md-4 col-lg-3 mt-4" v-for="(item, index) in items" :key="index">
      <ImageTitleGridItemComponent
          :svg-name=item.svgName
          :title=item.title
          :link=item.link
      />
    </div>
  </div>
</template>