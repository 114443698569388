<script>
import BaseButtonComponent from "@/components/button/BaseButtonComponent.vue";
import {inject} from "vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import CustomNotificationDialog from "@/components/CustomNotificationDialog.vue";

export default {
  components: {CustomNotificationDialog, LoadingComponent, BaseButtonComponent},
  data() {
    return {
      type: null,
      phaseId: null,
      matchId: null,
      username: "",
      password: "",
      isLoading: false,
      isSubmitButtonDisabled: true,
      isUsernameInputEnabled: false
    }
  },
  setup() {
    const axios = inject('axios')
    return {
      axios: axios
    }
  },
  mounted() {
    this.username = this.$route.fullPath.split("/")[2].toLowerCase()
    try {
      this.extractTypePhaseAndMatchId()
      localStorage.removeItem('token')
    } catch (e) {
      this.$router.replace('/')
    }
  },
  methods: {
    checkShouldEnableSubmitButton() {
      this.isSubmitButtonDisabled = !(this.username.length >= 3 && this.password.length >= 3)
    },
    extractTypePhaseAndMatchId() {
      const pathSegments = this.$route.path.split('/');
      if (pathSegments.length >= 7) {
        [, , this.type, , this.phaseId, , this.matchId] = pathSegments;
      }
      if (isNaN(this.phaseId) || isNaN(this.matchId)) {
        throw new Error(`id of phase or match not a number`)
      }
    },
    onPasswordInputChanged(event) {
      this.password = event.target.value
      this.checkShouldEnableSubmitButton()
    },
    onSubmitButtonClicked() {
      this.isLoading = true
      this.axios
          .post('/login', {
            phaseId: this.phaseId,
            username: "ProbelaufLiga3SR",
            password: this.password,
            application: this.type,
            source: 'rate',
          })
          .then(response => {
            this.isLoading = false
            if (response.data.success) {
              localStorage.setItem('token', response.data.value.token)
              this.$router.replace(`/${this.type}/liga/${this.phaseId}/spiel/${this.matchId}`)
            } else {
              this.$router.replace(`/login/${this.type}/liga/${this.phaseId}/spiel/${this.matchId}`)
            }
          })
          .catch((e) => {
            let notificationData = {
              title: "Hier stimmt etwas nicht!",
              message: `${e.response.message}`,
              buttonLabel: "Alles klar!"
            }
            this.isLoading = false
            switch (e.response.status) {
              case 401:
                notificationData.message = e.response.data.message
                break;
              case 500:
                notificationData.message = e.response.data.message
                break;
              default:
                notificationData.message = `<b>${e.response.status}</b> - das hätte nicht passieren sollen. <p>Melde dich gerne bei uns!</p>`
                break;
            }
            this.$refs.customNotificationDialog.show(notificationData)
          })
    },
    onUsernameInputChanged(event) {
      this.username = event.target.value
      this.checkShouldEnableSubmitButton()
    }
  }
}
</script>

<template>
  <div class="row">
    <div class="col-12 mt-4" v-if="isUsernameInputEnabled">
      <input
          class="form-control"
          placeholder="Benutzername"
          style="height: 48px"
          type="text"
          @input="onUsernameInputChanged"
      />
    </div>
    <div class="col-12 mt-4">
      <input
          class="form-control"
          placeholder="Passwort"
          style="height: 48px"
          type="password"
          @input="onPasswordInputChanged"
      />
    </div>
  </div>
  <div class="col-12 mt-3">
    <BaseButtonComponent
        :disabled=this.isSubmitButtonDisabled
        button-label="Login"
        @onClicked=onSubmitButtonClicked
    />
  </div>
  <CustomNotificationDialog
      ref="customNotificationDialog"
  />
  <LoadingComponent
      :is-loading=this.isLoading
  />
</template>
