<script>

import LoadingComponent from "@/components/LoadingComponent.vue";
import MatchListComponent from "@/components/MatchListComponent.vue";
import {inject} from "vue";


export default {
  components: {
    LoadingComponent,
    MatchListComponent
  },
  setup() {
    const axios = inject('axios')
    return {
      axios: axios
    }
  },
  mounted() {
    this.phaseId = this.$route.fullPath.split("/")[3]
    this.axios
        .get(`/overview/${this.phaseId}`)
        .then(value => {
          this.phaseName = value.data.value.name.fullName
          this.matches = this.mapMatches(value.data.value.matches)
          this.isLoading = false
        })
        .catch(() => {
          // todo handle error
        })
  },
  data() {
    return {
      phaseId: -1,
      phaseName: "",
      isLoading: true,
      matches: []
    }
  },
  methods: {
    mapMatches(matches) {
      return matches.map(match => {
        return {
          firstLine: `${match.homeTeam} - ${match.awayTeam}`,
          secondLine: `Heim : Gast`,
          link: `${this.$route.path}/spiel/${match.id}`
        }
      })
    }
  }
}

</script>

<template>
  <div class="row mt-2 mb-4">
    <div class="col-12">
      {{ this.phaseName }}
    </div>
  </div>

  <MatchListComponent
      :items=this.matches
  />

  <LoadingComponent
      :is-loading=this.isLoading
  />
</template>