<script>
/* global bootstrap */
import BaseButtonComponent from "@/components/button/BaseButtonComponent.vue";
import BaseBorderButtonComponent from "@/components/button/BaseBorderButtonComponent.vue";

export default {
  components: {BaseBorderButtonComponent, BaseButtonComponent},
  data() {
    return {
      modal: null,
      title: "",
      message: "",
      positiveButtonLabel: "",
      negativeButtonLabel: "",
    }
  },
  props: {
    onPositiveButtonClicked: {
      type: Function,
      default: null
    },
    onNegativeButtonClicked: {
      type: Function,
      default: null
    },
  },
  methods: {
    hide() {
      this.modal.hide()
    },
    onHandlePositiveButtonClicked() {
      if (this.onPositiveButtonClicked) {
        this.onPositiveButtonClicked()
      }
    },
    onHandleNegativeButtonClicked() {
      if (this.onNegativeButtonClicked) {
        this.onNegativeButtonClicked()
      }
    },
    show({title = "", message = "", negativeButtonLabel = "", positiveButtonLabel = ""} = {}) {
      this.title = title
      this.message = message
      this.negativeButtonLabel = negativeButtonLabel
      this.positiveButtonLabel = positiveButtonLabel
      this.modal.show()
    }
  },
  mounted() {
    this.modal = new bootstrap.Modal(this.$refs.confirmationDialog, {
      // keyboard: false
    });
  }
}
</script>

<template>
  <div class="modal fade" ref="confirmationDialog" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col-10">
              <b>{{ title }}</b>
            </div>
            <div class="col-2 text-end">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
            </div>
            <div class="row mt-2">
              <div class="col-12 mt-4">
                <span
                    v-html=message
                />
              </div>
            </div>
            <div class="col-12 mt-2" v-if="this.positiveButtonLabel">
              <BaseBorderButtonComponent
                  @click="onHandlePositiveButtonClicked"
                  :button-label=this.positiveButtonLabel
              />
            </div>
            <div class="col-12 mt-2" v-if="this.negativeButtonLabel">
              <BaseButtonComponent
                  @click="onHandleNegativeButtonClicked"
                  :button-label=this.negativeButtonLabel
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>